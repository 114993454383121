/**
 * Ao editar esse arquivo, verificar se há paridade
 * com o "utils/order_statuses.ts" da API, senão,
 * editar ele também
 */
export default {
    PROCESSING: 0,
    COMPLETE: 1,
    CORRECTING: 2,
    CANCELED: 3,
    WAITING_APPROVAL: 4,
    DRAFT: 5
}
