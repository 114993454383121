<template>
  <div>
    <f-default-header :title="$t('pages.orders.title')" :subtitle="$t('pages.orders.subtitle')" />

    <list-filter-vue store="order" @change="list()" />
    <f-list />

    <f-actions-btn-holder>
      <v-btn color="primary" elevation="0" large @click="create"> {{ $t('labels.new')}} </v-btn>
    </f-actions-btn-holder>
  </div>
</template>

<script>
import FList from "../../components/orders/List.vue";
import ListFilterVue from "../../components/shared/ListFilter.vue";

export default {
  components: {
    FList,
    ListFilterVue,
  },
  methods: {
    create() {
      this.$router.push({
        name: "order-create",
      });
    },
    async list() {
      this.$store.dispatch("system/loading", "data");
      await this.$store.dispatch("order/list");
      this.$store.dispatch("system/loading", "done");
    },
  },
};
</script>
