import ORDER_STATUS from "../../helpers/order_statuses";


export default {
  data: () => ({}),
  computed: {
    headers() {
      if (this.isAdmin) {
        return [
          {
            text: "ID",
            align: "start",
            sortable: true,
            value: "id",
          },
          {
            text: this.$t('pages.general.distributor'),
            align: "start",
            sortable: true,
            value: "distributor",
          },
          {
            text: this.$t('pages.general.list.number'),
            align: "start",
            sortable: true,
            value: "number",
          },
          {
            text: "Status",
            align: "start",
            sortable: true,
            value: "status_message",
          },
          {
            text: this.$t('pages.general.list.createdAt'),
            align: "end",
            sortable: true,
            value: "created_at",
          },
        ];
      }

      return [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "CNPJ",
          align: "start",
          sortable: false,
          value: "CNPJ",
        },
        {
          text: "Número",
          align: "start",
          sortable: false,
          value: "number",
        },
        {
          text: "Status",
          align: "start",
          sortable: true,
          value: "status_message",
        },
        {
          text: "Criado em",
          align: "end",
          sortable: true,
          value: "created_at",
        },
      ];
    },
    orders() {
      return this.$store.state.order.list.map((item) => {
        var status_message;
        switch (item.status) {
          case ORDER_STATUS.PROCESSING:
            status_message = this.$t('pages.general.status.waitingProcess');
            break;
          case ORDER_STATUS.COMPLETE:
            status_message = this.$t('pages.general.status.complete');
            break;
          case ORDER_STATUS.CORRECTING:
            status_message = this.$t('pages.general.status.waitingCorrection');
            break;
          case ORDER_STATUS.CANCELED:
            status_message = this.$t('pages.general.status.cancel');
            break;
          case ORDER_STATUS.WAITING_APPROVAL:
            status_message = this.$t('pages.general.status.waitingApproval');
            break;
          case ORDER_STATUS.DRAFT:
            status_message = this.$t('pages.general.status.draft');
            break;
          default:
            status_message = "-";
        }
        item.status_message = status_message;
        return item;
      });
    },
    isAdmin() {
      var isAdmin = true;

      if (this.$store.state.auth.user.distributorID) {
        return false;
      }

      return isAdmin;
    },
    listArgs() {
      return this.$store.state.order.listArgs;
    },
    // loading() { return !this.$store.state.system.loading.done }
  },
  // watch: {
  //   listArgs: {
  //     handler: function (_) {
  //       this.list();
  //     },
  //     deep: true,
  //   },
  // },
  async created() {
    this.list();
  },

  methods: {
    view(order) {
      this.$router.push({
        name: "order-view",
        params: {
          id: order.id,
        },
      });
    },
    async list() {
      this.$store.dispatch("system/loading", "data");
      await this.$store.dispatch("order/list");
      this.$store.dispatch("system/loading", "done");
    },
  },
};
